export const FootballManiaConfig = {
    "appmode": "android",
    "autoDetectAppMode": true,
    "url": {
        "imageBaseUrl": "",
        "teamPlayerImageUrl": "images",
        "dataBaseUrl": "/footapi/",
        "voteBaseUrl": "/"
    },
    "ajaxTimeout": 15000,
    "clientVersion": 1478,
    "apiVersion": 2800,
    "theme": "dark",
    "size": "medium",
    "defaultTheme": "dark",
    "defaultSize": "medium",
    "defaultHomeScreen": "home",
    "useAndroidThemeAndSizes": true,
    "useWebWorkers": false,
    "refreshIntervals": {
        "live": 30000,
        "today": 60000,
        "home": 60000,
        "match": 30000,
        "league": 30000,
        "agenda": 60000,
        "team": 60000,
        "commentaries": 30000,
        "countryLeague": 60000000,
        "player": 60000000,
        "commentary": 30000,
        "news": 60000,
        "ranking": 60000000,
        "forum": 60000
    },
    "cacheTimes": {
        "config": 600000,
        "home": 60000,
        "live": 10000,
        "today": 15000,
        "datefixtures": 20000,
        "match": 5000,
        "league": 30000,
        "leagueinfo": 60000,
        "translations": 864000000,
        "search": 60000,
        "team": 60000,
        "player": 120000,
        "countryLeagues": 60000,
        "suggestions": 60000,
        "commentaries": 20000,
        "news": 60000,
        "ranking": 120000,
        "forum": 120000,
        "ads": 2000,
        "userinfo": 600000
    },
    "sizes": {
        "fixtureListImageSize": 38,
        "fixtureListFlagSize": 38,
        "matchHeaderImageSize": 80
    },
    "graphics": {
        "animations": true,
        "listRenderMaxRenderCount": 20,
        "defaultMatchListType": 1
    },
    "enabledFeatures": {
        "search": true,
        "news": true,
        "chat": true,
        "forum": true,
        "matchChat": true,
        "playerImages": true,
        "teamImages": true,
        "stickyad": true,
        "housead": true,
        "userPredictions": true,
        "entertainment": true,
        "showHiddenLeagues": true,
        "showCompleteLeagueNames": true
    },
    "reservedWords": [
        "UEFA",
        "FIFA",
        "World Cup",
        "EFL"
    ],
    "hiddenLeagues": [],
    "enableVideo": true,
    "disabledVideoCountries": [
        "us",
        "es",
        "en"
    ],
    "userLocale": "da",
    "userLocaleFull": "nl-NL",
    "userRegionLocale": "Southern Europe",
    "autoSetLocale": true,
    "isRtl": false,
    "autoSetRtl": true,
    "is24HourFormat": true,
    "defaultLocale": "en",
    "allowableLocales": [
        "en",
        "es",
        "nl",
        "ru",
        "de",
        "fr",
        "ar",
        "it",
        "cs",
        "da",
        "el",
        "fa",
        "hi",
        "hr",
        "hu",
        "id",
        "in",
        "is",
        "ja",
        "ko",
        "mn",
        "no",
        "pa",
        "pt",
        "pl",
        "ro",
        "sv",
        "th",
        "tr",
        "uk",
        "vi",
        "zh",
        "zh-rCN",
        "zh-rHK",
        "zh-rTW"
    ],
    "availableNewsLocales": [
        "en",
        "us",
        "it",
        "es",
        "fr",
        "de",
        "nl",
        "be",
        "ru",
        "pt-BR"
    ],
    "availableChatLocales": [
        "en",
        "it",
        "de",
        "ru"
    ],
    "default_chat_locale": "en",
    "disallowedHighlightRegions": [
        "North America"
    ],
    "enabledOddsCountries": [
        "ma",
        "at",
        "cl",
        "ec",
        "ci",
        "pe",
        "kr",
        "br",
        "sl",
        "mz",
        "cr",
        "gn",
        "bf",
        "ga",
        "ar",
        "ve",
        "mm",
        "bj",
        "ml",
        "cm",
        "gh",
        "ug",
        "cg",
        "cd",
        "mx",
        "sn",
        "ng",
        "vn",
        "ke",
        "uz",
        "ca",
        "th",
        "zm",
        "az",
        "my",
        "id",
        "ec",
        "in",
        "bd"
    ],
    "newsLocaleRemap": {
        "en": "us"
    },
    "hiddenCountries": [],
    "fixedLeaguesOrder": {
        "Western Europe": {
            "countries": [
                "World Cup",
                "World Cup Qualifications",
                "Eurocups"
            ],
            "leagues": [
                "WorldCupBrazil2014",
                "WorldCupQualificationsEurope",
                "EurocupsUEFAChampionsLeague",
                "EurocupsUEFAEuropaLeague",
                "EurocupsEuropaChampionshipQualification",
                "EurocupsUEFAEuropaConferenceLeague",
                "WorldCupQualificationsSouthAmerica",
                "WorldCupQualificationsAsia",
                "WorldCupQualificationsNorthCentralAmerica",
                "WorldCupQualificationsAfrica",
                "WorldCupQualificationsOceania",
                "EnglandPremierLeague",
                "InternationalCupsOlympics",
                "InternationalCupsOlympicsWomen",
                "InternationalCupsFriendlies"
            ]
        },
        "Southern Europe": {
            "countries": [
                "World Cup",
                "World Cup Qualifications",
                "Eurocups"
            ],
            "leagues": [
                "WorldCupBrazil2014",
                "WorldCupQualificationsEurope",
                "EurocupsUEFAChampionsLeague",
                "EurocupsUEFAEuropaLeague",
                "EurocupsEuropaChampionshipQualification",
                "EurocupsUEFAEuropaConferenceLeague",
                "WorldCupQualificationsSouthAmerica",
                "WorldCupQualificationsAsia",
                "WorldCupQualificationsNorthCentralAmerica",
                "WorldCupQualificationsAfrica",
                "WorldCupQualificationsOceania",
                "EnglandPremierLeague",
                "InternationalCupsOlympics",
                "InternationalCupsOlympicsWomen",
                "InternationalCupsFriendlies"
            ]
        },
        "Eastern Europe": {
            "countries": [
                "World Cup",
                "World Cup Qualifications",
                "Eurocups"
            ],
            "leagues": [
                "WorldCupBrazil2014",
                "WorldCupQualificationsEurope",
                "EurocupsUEFAChampionsLeague",
                "EurocupsUEFAEuropaLeague",
                "EurocupsEuropaChampionshipQualification",
                "EurocupsUEFAEuropaConferenceLeague",
                "WorldCupQualificationsSouthAmerica",
                "WorldCupQualificationsAsia",
                "WorldCupQualificationsNorthCentralAmerica",
                "WorldCupQualificationsAfrica",
                "WorldCupQualificationsOceania",
                "EnglandPremierLeague",
                "InternationalCupsOlympics",
                "InternationalCupsOlympicsWomen",
                "InternationalCupsFriendlies"
            ]
        },
        "Central and Southeast Europe": {
            "countries": [
                "World Cup",
                "World Cup Qualifications",
                "Eurocups"
            ],
            "leagues": [
                "WorldCupBrazil2014",
                "WorldCupQualificationsEurope",
                "EurocupsUEFAChampionsLeague",
                "EurocupsUEFAEuropaLeague",
                "EurocupsEuropaChampionshipQualification",
                "EurocupsUEFAEuropaConferenceLeague",
                "WorldCupQualificationsSouthAmerica",
                "WorldCupQualificationsAsia",
                "WorldCupQualificationsNorthCentralAmerica",
                "WorldCupQualificationsAfrica",
                "WorldCupQualificationsOceania",
                "EnglandPremierLeague",
                "InternationalCupsOlympics",
                "InternationalCupsOlympicsWomen",
                "InternationalCupsFriendlies"
            ]
        },
        "Nordic Countries": {
            "countries": [
                "World Cup",
                "World Cup Qualifications",
                "Eurocups"
            ],
            "leagues": [
                "WorldCupBrazil2014",
                "WorldCupQualificationsEurope",
                "EurocupsUEFAChampionsLeague",
                "EurocupsUEFAEuropaLeague",
                "EurocupsEuropaChampionshipQualification",
                "EurocupsUEFAEuropaConferenceLeague",
                "WorldCupQualificationsSouthAmerica",
                "WorldCupQualificationsAsia",
                "WorldCupQualificationsNorthCentralAmerica",
                "WorldCupQualificationsAfrica",
                "WorldCupQualificationsOceania",
                "EnglandPremierLeague",
                "InternationalCupsOlympics",
                "InternationalCupsOlympicsWomen",
                "InternationalCupsFriendlies"
            ]
        },
        "Baltic Countries": {
            "countries": [
                "World Cup",
                "World Cup Qualifications",
                "Eurocups"
            ],
            "leagues": [
                "WorldCupBrazil2014",
                "WorldCupQualificationsEurope",
                "EurocupsUEFAChampionsLeague",
                "EurocupsUEFAEuropaLeague",
                "EurocupsEuropaChampionshipQualification",
                "EurocupsUEFAEuropaConferenceLeague",
                "WorldCupQualificationsSouthAmerica",
                "WorldCupQualificationsAsia",
                "WorldCupQualificationsNorthCentralAmerica",
                "WorldCupQualificationsAfrica",
                "WorldCupQualificationsOceania",
                "EnglandPremierLeague",
                "InternationalCupsOlympics",
                "InternationalCupsOlympicsWomen",
                "InternationalCupsFriendlies"
            ]
        },
        "South America": {
            "countries": [
                "World Cup",
                "World Cup Qualifications",
                "Eurocups"
            ],
            "leagues": [
                "WorldCupBrazil2014",
                "WorldCupQualificationsSouthAmerica",
                "InternationalSouthAmericaCONMEBOLLibertadores",
                "InternationalSouthAmericaCONMEBOLLibertadores",
                "InternationalSouthAmericaCONMEBOLSudamericana",
                "WorldCupQualificationsEurope",
                "WorldCupQualificationsAsia",
                "WorldCupQualificationsNorthCentralAmerica",
                "WorldCupQualificationsAfrica",
                "WorldCupQualificationsOceania",
                "InternationalCupsOlympics",
                "InternationalCupsOlympicsWomen",
                "InternationalCupsFriendlies"
            ]
        },
        "Central America": {
            "countries": [
                "World Cup",
                "World Cup Qualifications",
                "International North Central America"
            ],
            "leagues": [
                "WorldCupBrazil2014",
                "WorldCupQualificationsNorthCentralAmerica",
                "InternationalCupsConcacafLeague",
                "WorldCupQualificationsSouthAmerica",
                "WorldCupQualificationsEurope",
                "WorldCupQualificationsAsia",
                "WorldCupQualificationsAfrica",
                "WorldCupQualificationsOceania",
                "InternationalCupsOlympics",
                "InternationalCupsOlympicsWomen",
                "InternationalCupsFriendlies"
            ]
        },
        "North America": {
            "countries": [
                "World Cup",
                "World Cup Qualifications",
                "International North Central America"
            ],
            "leagues": [
                "WorldCupBrazil2014",
                "WorldCupQualificationsNorthCentralAmerica",
                "InternationalCupsConcacafLeague",
                "WorldCupQualificationsSouthAmerica",
                "WorldCupQualificationsEurope",
                "WorldCupQualificationsAsia",
                "WorldCupQualificationsAfrica",
                "WorldCupQualificationsOceania",
                "InternationalCupsOlympics",
                "InternationalCupsOlympicsWomen",
                "InternationalCupsFriendlies"
            ]
        },
        "Micronesia/Caribbean": {
            "countries": [
                "World Cup",
                "World Cup Qualifications",
                "International North Central America"
            ],
            "leagues": [
                "WorldCupBrazil2014",
                "WorldCupQualificationsOceania",
                "WorldCupQualificationsAsia",
                "WorldCupQualificationsSouthAmerica",
                "WorldCupQualificationsEurope",
                "WorldCupQualificationsAfrica",
                "WorldCupQualificationsNorthCentralAmerica",
                "InternationalCupsConcacafLeague",
                "InternationalCupsOlympics",
                "InternationalCupsOlympicsWomen",
                "InternationalCupsFriendlies"
            ]
        },
        "Southern and Central Asia": {
            "countries": [
                "World Cup",
                "World Cup Qualifications"
            ],
            "leagues": [
                "WorldCupBrazil2014",
                "WorldCupQualificationsAsia",
                "WorldCupQualificationsEurope",
                "WorldCupQualificationsSouthAmerica",
                "WorldCupQualificationsOceania",
                "WorldCupQualificationsAfrica",
                "WorldCupQualificationsNorthCentralAmerica",
                "InternationalCupsOlympics",
                "InternationalCupsOlympicsWomen",
                "InternationalCupsFriendlies"
            ]
        },
        "Northern Africa": {
            "countries": [
                "World Cup",
                "World Cup Qualifications"
            ],
            "leagues": [
                "WorldCupBrazil2014",
                "WorldCupQualificationsAfrica",
                "WorldCupQualificationsAsia",
                "WorldCupQualificationsEurope",
                "WorldCupQualificationsSouthAmerica",
                "WorldCupQualificationsOceania",
                "WorldCupQualificationsNorthCentralAmerica",
                "InternationalAfricaCAFConfederationsCup",
                "EurocupsUEFAChampionsLeague",
                "InternationalCupsOlympics",
                "InternationalCupsOlympicsWomen",
                "InternationalCupsFriendlies"
            ]
        },
        "Central Africa": {
            "countries": [
                "World Cup",
                "World Cup Qualifications",
                "International Africa"
            ],
            "leagues": [
                "WorldCupBrazil2014",
                "WorldCupQualificationsAfrica",
                "WorldCupQualificationsAsia",
                "WorldCupQualificationsEurope",
                "WorldCupQualificationsSouthAmerica",
                "WorldCupQualificationsOceania",
                "WorldCupQualificationsNorthCentralAmerica",
                "InternationalAfricaCAFConfederationsCup",
                "InternationalCupsOlympics",
                "InternationalCupsOlympicsWomen",
                "InternationalCupsFriendlies"
            ]
        },
        "Western Africa": {
            "countries": [
                "World Cup",
                "World Cup Qualifications",
                "International Africa"
            ],
            "leagues": [
                "WorldCupBrazil2014",
                "WorldCupQualificationsAfrica",
                "WorldCupQualificationsAsia",
                "WorldCupQualificationsEurope",
                "WorldCupQualificationsSouthAmerica",
                "WorldCupQualificationsOceania",
                "WorldCupQualificationsNorthCentralAmerica",
                "InternationalAfricaCAFConfederationsCup",
                "InternationalCupsOlympics",
                "InternationalCupsOlympicsWomen",
                "InternationalCupsFriendlies"
            ]
        },
        "Southern Africa": {
            "countries": [
                "World Cup",
                "World Cup Qualifications",
                "International Africa"
            ],
            "leagues": [
                "WorldCupBrazil2014",
                "WorldCupQualificationsAfrica",
                "WorldCupQualificationsAsia",
                "WorldCupQualificationsEurope",
                "WorldCupQualificationsSouthAmerica",
                "WorldCupQualificationsOceania",
                "WorldCupQualificationsNorthCentralAmerica",
                "InternationalAfricaCAFConfederationsCup",
                "InternationalCupsOlympics",
                "InternationalCupsOlympicsWomen",
                "InternationalCupsFriendlies"
            ]
        },
        "Eastern Africa": {
            "countries": [
                "World Cup",
                "World Cup Qualifications",
                "International Africa"
            ],
            "leagues": [
                "WorldCupBrazil2014",
                "WorldCupQualificationsAfrica",
                "WorldCupQualificationsAsia",
                "WorldCupQualificationsEurope",
                "WorldCupQualificationsSouthAmerica",
                "WorldCupQualificationsOceania",
                "WorldCupQualificationsNorthCentralAmerica",
                "InternationalAfricaCAFConfederationsCup",
                "InternationalCupsOlympics",
                "InternationalCupsOlympicsWomen",
                "InternationalCupsFriendlies"
            ]
        },
        "Caribbean": {
            "countries": [
                "World Cup",
                "World Cup Qualifications"
            ],
            "leagues": [
                "WorldCupBrazil2014",
                "WorldCupQualificationsSouthAmerica",
                "WorldCupQualificationsNorthCentralAmerica",
                "WorldCupQualificationsEurope",
                "WorldCupQualificationsAfrica",
                "WorldCupQualificationsAsia",
                "WorldCupQualificationsOceania",
                "InternationalCupsOlympics",
                "InternationalCupsOlympicsWomen",
                "InternationalCupsFriendlies"
            ]
        },
        "Middle East": {
            "countries": [
                "World Cup",
                "World Cup Qualifications"
            ],
            "leagues": [
                "WorldCupBrazil2014",
                "WorldCupQualificationsAsia",
                "WorldCupQualificationsEurope",
                "WorldCupQualificationsSouthAmerica",
                "WorldCupQualificationsNorthCentralAmerica",
                "WorldCupQualificationsAfrica",
                "WorldCupQualificationsOceania",
                "InternationalCupsOlympics",
                "InternationalCupsOlympicsWomen",
                "InternationalCupsFriendlies"
            ]
        },
        "Southeast Asia": {
            "countries": [
                "World Cup",
                "World Cup Qualifications"
            ],
            "leagues": [
                "WorldCupBrazil2014",
                "WorldCupQualificationsAsia",
                "WorldCupQualificationsEurope",
                "WorldCupQualificationsSouthAmerica",
                "WorldCupQualificationsNorthCentralAmerica",
                "WorldCupQualificationsAfrica",
                "WorldCupQualificationsOceania",
                "InternationalCupsOlympics",
                "InternationalCupsOlympicsWomen",
                "InternationalCupsFriendlies"
            ]
        },
        "Eastern Asia": {
            "countries": [
                "World Cup",
                "World Cup Qualifications"
            ],
            "leagues": [
                "WorldCupBrazil2014",
                "WorldCupQualificationsAsia",
                "WorldCupQualificationsEurope",
                "WorldCupQualificationsSouthAmerica",
                "WorldCupQualificationsNorthCentralAmerica",
                "WorldCupQualificationsAfrica",
                "WorldCupQualificationsOceania",
                "InternationalCupsOlympics",
                "InternationalCupsOlympicsWomen",
                "InternationalCupsFriendlies"
            ]
        },
        "Southern Asia": {
            "countries": [
                "World Cup",
                "World Cup Qualifications"
            ],
            "leagues": [
                "WorldCupBrazil2014",
                "WorldCupQualificationsAsia",
                "WorldCupQualificationsEurope",
                "WorldCupQualificationsSouthAmerica",
                "WorldCupQualificationsNorthCentralAmerica",
                "WorldCupQualificationsAfrica",
                "WorldCupQualificationsOceania",
                "InternationalCupsOlympics",
                "InternationalCupsOlympicsWomen",
                "InternationalCupsFriendlies"
            ]
        },
        "Australia and New Zealand": {
            "countries": [
                "World Cup",
                "World Cup Qualifications"
            ],
            "leagues": [
                "WorldCupBrazil2014",
                "WorldCupQualificationsOceania",
                "WorldCupQualificationsAsia",
                "WorldCupQualificationsEurope",
                "WorldCupQualificationsSouthAmerica",
                "WorldCupQualificationsNorthCentralAmerica",
                "WorldCupQualificationsAfrica",
                "InternationalCupsOlympics",
                "InternationalCupsOlympicsWomen",
                "InternationalCupsFriendlies"
            ]
        },
        "Melanesia": {
            "countries": [
                "World Cup",
                "World Cup Qualifications"
            ],
            "leagues": [
                "WorldCupBrazil2014",
                "WorldCupQualificationsOceania",
                "WorldCupQualificationsAsia",
                "WorldCupQualificationsEurope",
                "WorldCupQualificationsSouthAmerica",
                "WorldCupQualificationsNorthCentralAmerica",
                "WorldCupQualificationsAfrica",
                "InternationalCupsOlympics",
                "InternationalCupsOlympicsWomen",
                "InternationalCupsFriendlies"
            ]
        },
        "Polynesia": {
            "countries": [
                "World Cup",
                "World Cup Qualifications"
            ],
            "leagues": [
                "WorldCupBrazil2014",
                "WorldCupQualificationsOceania",
                "WorldCupQualificationsAsia",
                "WorldCupQualificationsEurope",
                "WorldCupQualificationsSouthAmerica",
                "WorldCupQualificationsNorthCentralAmerica",
                "WorldCupQualificationsAfrica",
                "InternationalCupsOlympics",
                "InternationalCupsOlympicsWomen",
                "InternationalCupsFriendlies"
            ]
        },
        "Micronesia": {
            "countries": [
                "World Cup",
                "World Cup Qualifications"
            ],
            "leagues": [
                "WorldCupBrazil2014",
                "WorldCupQualificationsOceania",
                "WorldCupQualificationsAsia",
                "WorldCupQualificationsEurope",
                "WorldCupQualificationsSouthAmerica",
                "WorldCupQualificationsNorthCentralAmerica",
                "WorldCupQualificationsAfrica",
                "InternationalCupsOlympics",
                "InternationalCupsOlympicsWomen",
                "InternationalCupsFriendlies"
            ]
        },
        "Unknown": {
            "countries": [
                "World Cup",
                "World Cup Qualifications"
            ],
            "leagues": [
                "WorldCupBrazil2014",
                "WorldCupQualificationsOceania",
                "WorldCupQualificationsAsia",
                "WorldCupQualificationsEurope",
                "WorldCupQualificationsSouthAmerica",
                "WorldCupQualificationsNorthCentralAmerica",
                "WorldCupQualificationsAfrica",
                "InternationalCupsOlympics",
                "InternationalCupsOlympicsWomen",
                "InternationalCupsFriendlies"
            ]
        }
    },
    "fixedRegions": [
        "World Cup",
        "World Cup Qualifications",
        "Eurocups",
        "International South America",
        "International Cups",
        "International Africa",
        "International North Central America",
        "International Asia",
        "International Oceania"
    ],
    "hideCountryTitleFor": [
        "International Cups",
        "Eurocups",
        "World Cup",
        "International South America",
        "International Africa",
        "International North Central America",
        "International Asia",
        "International Oceania",
        "World Cup Qualifications"
    ]
};