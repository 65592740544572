import {DateTimecode} from "./datetime.js";
import {Localizer} from "./localizer.js";

export const MatchUtil = {
    //TODO: optimize code below. its used frequently in scrolling lists.
    getLocalizedScoreTime : function(mi){
        var s;

        var foundProperTime = false;

       // console.log(mi,mi.scoretime)
        if (!MatchUtil.isValidScore(mi)){
            //console.log("no valid")
            s = MatchUtil.getLocalizedTime(mi.time,mi.date);
            foundProperTime = true;
        }
        else{
            if (AppInstance.getConfig().isRTL){
                var Sparts = mi.scoretime.split("-");
                s = "\u200E"+parts[1]+" - "+parts[0]; //tag needed to prevent reversing string to RTL @see http://stackoverflow.com/questions/27910465/how-to-prevent-automatic-right-to-left-text-direction-for-hebrew-and-arabic
            }
            else{
                s = mi.scoretime;
            }
        }
        return s;
    },

    getLocalScore : function(mi,complyRTL){
       // console.log(mi,mi.scoretime)
        if (!MatchUtil.isValidScore(mi)){
            return null;
        }
        else{
            var parts = mi.scoretime.split("-");
            if (complyRTL && AppInstance.getConfig().isRTL){
                return parts[1];
            }
            else{
                return parts[0];
            }
        }
    },

    getAwayScore : function(mi,complyRTL){
        // console.log(mi,mi.scoretime)
        if (!MatchUtil.isValidScore(mi)){
            return null;
        }
        else{
            var parts = mi.scoretime.split("-");
            if (complyRTL && AppInstance.getConfig().isRTL){
                return parts[0];
            }
            else{
                return parts[1];
            }
        }
    },

    getLocalAggregateScore : function(mi,complyRTL){
          if (!MatchUtil.hasAggregatedScore(mi)){
                return null;
          }
          else{
                var parts = mi.aggregateScore.split("-");
                if (complyRTL && AppInstance.getConfig().isRTL){
                 return parts[1].trim();
                }
                else{
                 return parts[0].trim();
                }
          }
    },

    getAwayAggregateScore : function(mi,complyRTL){
         if (!MatchUtil.hasAggregatedScore(mi)){
               return null;
         }
         else{
               var parts = mi.aggregateScore.split("-");
               if (complyRTL && AppInstance.getConfig().isRTL){
                return parts[0].trim();
               }
               else{
                return parts[1].trim();
               }
         }
    },

    /**
     * get the day
     * @param mi
     */
    getLocalizedDayTimecode(mi){
        return new Date(Date.UTC(mi.date[6]+""+mi.date[7]+""+mi.date[8]+""+mi.date[9],
            parseInt(mi.date[3]+""+mi.date[4])-1,
            mi.date[0]+""+mi.date[1],
            0,
            0,
            0,
            0
        )).getTime();
    },

    getLocalizedDayNoUTCTimecode(mi){
        return new Date(mi.date[6]+""+mi.date[7]+""+mi.date[8]+""+mi.date[9],
            parseInt(mi.date[3]+""+mi.date[4])-1,
            mi.date[0]+""+mi.date[1],
            0,
            0,
            0,
            0
        ).getTime();
    },

    getLocalizedTimecode(mi){
        if (!mi.time){
            return new Date(Date.UTC(mi.date[6]+""+mi.date[7]+""+mi.date[8]+""+mi.date[9],
                parseInt(mi.date[3]+""+mi.date[4])-1,
                mi.date[0]+""+mi.date[1],
                0,
                0,
                0,
                0
            )).getTime();
        }
        else{
            return new Date(Date.UTC(mi.date[6]+""+mi.date[7]+""+mi.date[8]+""+mi.date[9],
                parseInt(mi.date[3]+""+mi.date[4])-1,
                mi.date[0]+""+mi.date[1],
                mi.time[0] + "" + mi.time[1],
                mi.time[3] + "" + mi.time[4],
                0,
                0
                )).getTime();
        }
    },

    getTimecode : function(mi){
        if (!mi.time){
            if (!this.tempDateTime2) this.tempDateTime2 = new DateTimecode();
            var split = mi.date.split("/");
            //this.tempDateTime.clear()
            this.tempDateTime2.setYear(split[2]);
            this.tempDateTime2.setMonth(split[1]-1);
            this.tempDateTime2.setDate(split[0]);
            //this.tempDateTime.setTime(mi.date+":00:00:00:00");
            return this.tempDateTime2.getTimecode();
        }
        else{
            if (!this.tempDateTime) this.tempDateTime = new DateTimecode();
            this.tempDateTime.setTime(mi.date.replaceAll(".","/")+":"+mi.time+":00:00");
            return this.tempDateTime.getTimecode();
        }
    },

    getLocalizedDate : function(mi,long,alternateFormat){

        if (!/^([0-1][0-9]|2[0-3]):([0-5][0-9])$/.test(mi.time)) mi.time = "00:00"; //hack for IOS
        if (!mi.time){
            if (!this.tempDateTime2) this.tempDateTime2 = new DateTimecode();
            var split = mi.date.split("/");
            //this.tempDateTime.clear()
            this.tempDateTime2.setYear(split[2]);
            this.tempDateTime2.setMonth(split[1]);
            this.tempDateTime2.setDate(split[0]);
            //this.tempDateTime.setTime(mi.date+":00:00:00:00");
            if (!long) {
                if (alternateFormat){
                    return this.tempDateTime2.toLocaleDateStringAlt2();
                }
                return this.tempDateTime2.toLocaleDateString();
            }
            return this.tempDateTime2.toLocaleDateStringLong();
        }
        else{

            if (!this.tempDateTime) this.tempDateTime = new DateTimecode();
            this.tempDateTime.setTime(mi.date.replaceAll(".","/")+":"+mi.time+":00:00");
            if (!long) {
                if (alternateFormat){
                    return this.tempDateTime.toLocaleDateStringAlt2();
                }
                return this.tempDateTime.toLocaleDateString();
            }
            return this.tempDateTime.toLocaleDateStringLong();
        }
    },

    localizedTimeDateFormat : null,
    tempDateTime : null,
    getLocalizedTime : function(time, date) {

        if (!this.tempDateTime) this.tempDateTime = new DateTimecode();

        if (!time) return "-";
        this.tempDateTime.setTime(date+":"+time+":00:00");
        return this.tempDateTime.toLocaleTimeString();
    },

    getLocalizedAggregateScore : function(mi,clean){
        if (mi.aggregateWinner == 1){
            var split = mi.aggregateScore.replaceAll(" ","").split("-");

            if (FootballManiaConfig.isRtl){
                return "("+split[1]+"-<p style='display:inline' class='greentext'>"+split[0]+"</p>)";
            }

            return "(<p style='display:inline' class='greentext'>"+split[0]+"</p>-"+split[1]+")";
        }
        else if (mi.aggregateWinner == 2){
            var split = mi.aggregateScore.replaceAll(" ","").split("-");

            if (FootballManiaConfig.isRtl){
                return "(<p style='display:inline' class='greentext'>"+split[1]+"</p>-"+split[0]+")";
            }

            return "("+split[0]+"-<p style='display:inline' class='greentext'>"+split[1]+"</p>)";
        }
        else{

            if (FootballManiaConfig.isRtl){
                mi.aggregateScore.split("").reverse().join("");
            }

            return "("+mi.aggregateScore.replaceAll(" ","")+")";
        }
    },

    getFullStatus : function(mi){

        //console.log("get full status",mi.status);
        if (mi.status == null || mi.status == ""){
            return ""
        }
        else if (mi.status == "FT"){
            return Localizer.getString("finaltime");
        }
        else if (!isNaN(mi.status)){
            if (mi.status == "45" && mi.addedTime_period1){
                return mi.status + "' <z style=''>+"+mi.addedTime_period1+"</z>";
            }
            else if (mi.status == "90" && mi.addedTime_period2){
                return mi.status + "' +"+mi.addedTime_period2+"";
            }
            else {
                return mi.status + "'";
            }
        }
        else if (mi.status == "HT"){
            return Localizer.getString("halftime");
        }
        else if (mi.status == "P"){
            //console.log("----------> status mi",mi.status)
            if (mi.penaltyLocalTeam != null && mi.penaltyVisitorTeam != null){
                return "pen "+mi.penaltyLocalTeam+" - "+mi.penaltyVisitorTeam;
            }
            return "p."
        }
        else if (mi.status == "Pen."){ //finished penalties
            //console.log("----------2> status mi",mi.status,mi)
            if (mi.penaltyLocalTeam != null && mi.penaltyVisitorTeam != null){
                return "pen "+mi.penaltyLocalTeam+" - "+mi.penaltyVisitorTeam;
            }
            return "p."
        }
        else if (mi.status == "Postp."){
            return Localizer.getString("postponed");
        }
        else if (mi.status == "Aban."){
            return Localizer.getString("abandoned");
        }
        else if (mi.status == "Int."){
            return Localizer.getString("interrupted");
        }
        else if (mi.status == "AET"){
            return Localizer.getString("after_extra_time");
        }
        else if (mi.status == "Cancl."){
            return Localizer.getString("cancelled");
        }
        else if (mi.status == "TBA"){
            return Localizer.getString("to_be_announced");
        }
        else if (mi.status == "Break"){
            return Localizer.getString("pause");
        }
        else if (mi.status == "Susp."){
            return Localizer.getString("suspended");
        }
        return "";
        return "un: "+mi.status;
    },

    getShortStatus : function(mi){

        // console.log("get short status ",mi.status);
        if (mi.status == null || mi.status == ""){
            return "X";
        }
        if (!isNaN(mi.status)){
            if (mi.status == "45" && mi.addedTime_period1){
                return mi.status + "' <z style='font-size:9px;'>+"+mi.addedTime_period1+"</z>";
            }
            else if (mi.status == "90" && mi.addedTime_period2){
                return mi.status + "' <z style='font-size:9px;'>+"+mi.addedTime_period2+"</z>";
            }
            else {
                return mi.status + "'";
            }
        }
        else if (mi.status == "FT"){
            return Localizer.getString("finaltime_short");
        }
        else if (mi.status == "HT"){
            return Localizer.getString("halftime_short");
        }
        else if (mi.status == "Pen."){
            return Localizer.getString("penalty_short");
            if (mi.penaltyHome != null && mi.penaltyVisitor != null){
                return  "pen "+this.getLocalizedPenalty(mi);
            }
        }
        else if (mi.status == "P"){
            return Localizer.getString("penalty_short");
        }
        else if (mi.status == "Postp."){
            return Localizer.getString("postponed_short");
        }
        else if (mi.status == "Aban."){
            return Localizer.getString("abandoned_short");
        }
        else if (mi.status == "Int."){
            return Localizer.getString("interrupted_short");
        }
        else if (mi.status == "AET"){
            return Localizer.getString("after_extra_time_short");
        }
        else if (mi.status == "Cancl."){
            return Localizer.getString("cancelled_short");
        }
        else if (mi.status == "TBA"){
            return Localizer.getString("to_be_announced_short");
        }
        else if (mi.status == "Break"){
            return Localizer.getString("pause");
        }
        else if (mi.status == "Susp."){
            return Localizer.getString("suspended_short");
        }

        return "";
    },

    isFinished : function(mi){
        return (mi.status != null && mi.status == "FT" || mi.status == "AET" || mi.status == "Pen." || mi.status == "Aban.");
    },

    isValidScore : function(mi){
        return null != (mi.scoretime.replaceAll(" ","").match("([0-9]+)-([0-9]+)"));
    },

    isPlaying : function(mi){
        return mi.status != null && (!isNaN(mi.status) || mi.status == "HT" || mi.status == "P") || mi.status == "Break";
    },

    isHalftime : function(mi){
        return mi.status != null && mi.status == "HT";
    },

    isLivePenalty : function(mi){
        return (mi.status != null && (mi.status == "P"));
    },

    getLocalizedPenalty : function(mi){
        if (mi.penaltyLocalTeam != null && mi.penaltyVisitorTeam != null) {
            if (FootballManiaConfig.isRtl){
                return mi.penaltyVisitorTeam + " - " + mi.penaltyLocalTeam;
            }
            return mi.penaltyLocalTeam + " - " + mi.penaltyVisitorTeam;
        }
        else{
            return " - ";
        }
    },

    isPenalty : function(mi){
        return (mi.status != null && mi.status == "Pen.");
    },

    isPostponed : function(mi){
        return (mi.status != null && mi.status == "Postp.");
    },

    isAbandoned : function(mi){
        return (mi.status != null && mi.status == "Aban.");
    },

    isCancelled : function(mi){
        return (mi.status != null && (mi.status == "Cancl."));
    },

    isInterrupted : function(mi){
        return (mi.status != null && mi.status == "Int.");
    },

    isSuspended : function(mi){
        return (mi.status != null && mi.status == "Susp.");
    },

    isToBeAnnounced : function(mi){
        return Boolean.valueOf(mi.status != null && mi.status == "TBA");
    },

    hasAggregatedScore : function(mi){
        if (mi.aggregateScore) return true;
        return false;
    },

    hasPenaltyScore : function(mi){
        return (mi.penaltyLocalTeam != null && mi.penaltyVisitorTeam != null);
    },

    isInteger : function(s) {
        try {
            Integer.parseInt(s);
            return true;
        } catch(e) {
            return false;
        }
    }
};